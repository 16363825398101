:root {
  --blue-100: #52c1ff;
  --blue-200: #3ba3ff;
  --blue-300: #253ec6;
  --blue-400: #2e40a1;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

.mt-2 {
  margin-top: 5px;
  margin-bottom: 2px;
}

.mb-2 {
  margin-bottom: 2px;
  display: block;
}

.inline {
  display: inline-flex;
  justify-content: space-evenly;
}

.flex-n {
  flex-wrap: wrap !important;
}

.block {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  flex: 1 1 0%;
  max-width: 985px;
}

.step-layout {
  display: block;
  background-color: white;
  padding-left: 4rem;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100vh;
  background-color: #dff9ff;
}

.icon-sm {
  width: 25px;
  margin-right: 5px;
}

.flex.justify-content-between {
  display: flex;
  justify-content: space-between;
}

.semi-bold {
  font-weight: 600;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.button-flex-content {
  justify-content: center !important;
  align-items: center !important;
}

.button-flex-content > span {
  font-size: 0.8rem;
  line-height: 10px;
}

.break-word {
  word-break: normal;
}

.button-icon {
  width: 25px;
  height: 30px;
  margin-right: 5px;
  vertical-align: middle;
}

.flex.items-center {
  display: flex;
  align-items: center;
}

.next-button {
  background-color: #03a4ff;
  color: #fff;
  height: auto;
  padding: 0.5rem 1.5rem;
  border: none;
  display: block;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  font-size: 1rem;
  width: auto;
}

.primary-button {
  background-color: #03a4ff;
  color: #fff;
  height: auto;
  padding: 0.5rem 1.5rem;
  border: none;
  display: block;
  border-radius: 5px;
  width: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.flex-end {
  justify-content: flex-end;
  float: right;
}

.blue {
  color: var(--blue-300);
}

.back-button {
  background-color: #d3f7ff;
  color: #3a4b4f;
}

.button-group > .next-button {
  display: inline-block;
  margin-right: 15px;
}

.types {
  display: flex;
  line-height: 25px !important;
}

.button-text {
  line-height: 5px;
}

.br {
  display: block;
  margin-bottom: 0em;
}

.hours {
  font-size: 0.85rem;
}

.next-button:hover,
.next-button:active,
.next-button:focus,
.primary-button:hover,
.primary-button:active,
.primary-button:focus {
  background-color: var(--blue-300);
  color: #fff;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-align: center;
  height: 2em;
  line-height: 25px !important;
}

.button:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: var(--blue-300) !important;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: auto;
  padding: 10px;
}

/* Hide the hideous left border on the radio buttons. */
.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.ant-radio-button-wrapper {
  border-left-width: 1px;
}

.center {
  text-align: center;
  display: block;
  margin: 15px auto 0;
}

.flex .justify-content-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flexible-time-button {
  background-color: #03a4ff;
  border-radius: 5px !important;
  color: #fff;
  width: "100%";
  display: flex;
  justify-content: space-between;
}

.time-button {
  border-radius: 5px !important;
  width: "100%";
  display: flex;
  justify-content: space-between;
}

.right {
  text-align: right;
}

.right > small {
  font-weight: 400;
  display: block;
  float: right;
  line-height: 15px;
  width: 60%;
}

.calendar {
  border-radius: 18px;
  width: 90% !important;
  border: 1px solid #d3d3d3 !important;
  padding: 20px;
}

.calendar {
  font-family: Poppins !important;
}

.react-calendar__navigation button {
  font-weight: 600;
  color: #006bde;
}

.react-calendar__tile--active {
  border-radius: 4px;
  background-color: var(--blue-300) !important;
}
.react-calendar__month-view__days__day--weekend {
  color: inherit;
}

.react-calendar__tile:disabled,
.react-calendar__navigation button[disabled] {
  background-color: #fff !important;
  color: #000 !important;
  opacity: 0.5;
}

.react-calendar__navigation__arrow {
  font-size: 2em;
  color: var(--blue-300) !important;
}

/* step-3 */
.input {
  width: 100%;
  height: auto;
  margin-top: 6px;
  margin-bottom: 10px !important;
  background: #ffffff;
  border: 1px solid #e2e2e2 !important;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.tip {
  display: block;
  width: 10% !important;
}

/* .btn-small, */
.ant-radio-group-small .ant-radio-button-wrapper {
  padding: 8px 15px;
  margin-top: 5px;
  height: auto;
}

.btn-small:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.btn-small:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-input-prefix {
  background-color: #dff9ff;
  padding: 2px 10px;
  font-size: 0.9rem;
  border-radius: 5px;
}

.optional {
  opacity: 0.7;
}

.StripeElement {
  height: auto;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-top: 6px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.CardNumberField > div > span > input {
  border: 1px solid #d9d9d9;
  background-color: red;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media (max-width: 500px) {
  .types {
    display: block;
  }

  .types > .button:first-of-type {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .types > .button:last-of-type {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .step-layout {
    padding-left: 0;
  }

  .input {
    width: 100%;
  }

  .calendar {
    width: 100% !important;
  }
  .mobile-step-layout {
    padding-bottom: 4rem;
  }
}

@media only screen and (max-width: 768px) {
  .tip {
    display: block;
    width: 30% !important;
  }

  .sidebar {
    min-height: 100% !important;
  }

  .w-full > .next-button,
  .w-full > .back-button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .calendar {
    width: 100% !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 991px) {
  .calendar {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1365px) {
  .step-layout {
    padding-left: 0;
  }
}
